import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import LifecycleWorkspaceDetails from "app/components/insights/workspaces/report-details/components/lifecycle-workspace-details";
import GuestService from "app/services/guests";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import isInTeamsApp from "app/utils/isInTeams";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import GuestReviewWorkflowInsightReport from "./guest-review-workflow-insight-report";
import "./guest-review-workflow.scss";
var GuestReviewWorkflow = React.memo(function (props) {
    //console.log("Request Param Guid", props.match.params.guid);
    var intl = useIntl();
    var dispatch = useDispatch();
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var policyWorkspace = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.item; });
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var _a = React.useState(null), isCurrentUserApprover = _a[0], setIsCurrentUserApprover = _a[1];
    React.useEffect(function () {
        if (props.match.params.siteId !== undefined) {
            GuestService.IsUserPolicyApprover(props.match.params.siteId, "Review").then(function (isApprover) {
                setIsCurrentUserApprover(isApprover);
            });
            dispatch(LifecyclePolicyWorkspaceStore.Clear());
            dispatch(LifecyclePolicyWorkspaceStore.Load(props.match.params.siteId));
            dispatch(LifecyclePolicyWorkspaceStore.LoadReportItem(props.match.params.siteId));
            dispatch(LifecyclePolicyWorkspaceStore.LoadHistory(props.match.params.siteId));
        }
        return function () {
            dispatch(LifecyclePolicyWorkspaceStore.Clear());
            //    setUpdateStatusContinously(false);
        };
    }, [props.match.params.siteId]);
    return (React.createElement("div", { className: "guest-review-workflow fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: intl.formatMessage({ id: "page.guests.guest_review_workflow.page_title", defaultMessage: "Workspace Guest Review" }), subTitle: intl.formatMessage({ id: "page.guests.guest_review_workflow.page_subtitle", defaultMessage: "Guest Review" }) }),
        React.createElement(PageHeader.SetRightContent, null, currentUserHasRole(OrchRole.Admin) ? (React.createElement(React.Fragment, null,
            React.createElement(NavLink, { to: "/lifecycle/listing/" + (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteId) },
                React.createElement("button", { className: "btn btn-primary" },
                    React.createElement(FormattedMessage, { id: "page.guests.guest_review_workflow.view_details", defaultMessage: "View Details" }))))) : null),
        currentUserHasRole(OrchRole.Admin) && isInTeamsApp() === true ? (React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(Portlet, { className: "admin-options" },
                    React.createElement("div", { className: "wrapper" },
                        React.createElement("div", null,
                            React.createElement(FormattedMessage, { id: "page.guests.guest_review_workflow.admin_options", defaultMessage: "Admin Options:" }),
                            " "),
                        React.createElement(NavLink, { to: "/lifecycle/listing/" + (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteId) },
                            React.createElement("button", { className: "btn btn-primary" },
                                React.createElement(FormattedMessage, { id: "page.guests.guest_review_workflow.view_details", defaultMessage: "View Details" })))))))) : null,
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(LifecycleWorkspaceDetails, { showDaysInactive: true, minimalMode: true }))),
        React.createElement(Row, null,
            React.createElement(Col, { className: "report-column" },
                React.createElement(Portlet, null, (reportItem === null || reportItem === void 0 ? void 0 : reportItem.GroupId) && (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.SiteId) && isCurrentUserApprover !== null ? (isCurrentUserApprover === false ? (React.createElement("div", { className: "no-an-approver" })) : (React.createElement(GuestReviewWorkflowInsightReport, { groupId: reportItem === null || reportItem === void 0 ? void 0 : reportItem.GroupId }))) : (React.createElement(PortletBody, null,
                    React.createElement(SkeletonLoaderFakeBox, { bodyWidth: 600, bodyHeight: 100 }))))))));
});
export default GuestReviewWorkflow;
