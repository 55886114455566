import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import React from "react";
import { useSelector } from "react-redux";
var pendoScript = "(function(apiKey) {\n   (function(p, e, n, d, o) {\n      var v, w, x, y, z;\n      o = p[d] = p[d] || {};\n      o._q = o._q || [];\n      v = [\"initialize\", \"identify\", \"updateOptions\", \"pageLoad\", \"track\"];\n      for (w = 0, x = v.length; w < x; ++w)\n         (function(m) {\n            o[m] =\n               o[m] ||\n               function() {\n                  o._q[m === v[0] ? \"unshift\" : \"push\"]([m].concat([].slice.call(arguments, 0)));\n               };\n         })(v[w]);\n      y = e.createElement(n);\n      y.async = !0;\n      y.src = \"https://content.analytics-p.orchestry.com/agent/static/\" + apiKey + \"/pendo.js\";\n      z = e.getElementsByTagName(n)[0];\n      z.parentNode.insertBefore(y, z);\n   })(window, document, \"script\", \"pendo\");\n})(\"4f8137c3-9860-4de8-4959-c9ef61c05361\");\n";
export var usePendo = function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var userCurrentState = useSelector(function (state) { return state.userCurrentState.item; });
    var _a = React.useState(false), isScriptLoaded = _a[0], setisScriptLoaded = _a[1];
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    React.useEffect(function () {
        if (orchestryContext.IsPendoEnabled) {
            console.log("Pendo > Consent: ".concat(orchestryContext.IsPendoEnabled));
            var script = document.createElement("script");
            script.innerHTML = pendoScript;
            document.head.appendChild(script);
            var waitForPendo_1 = function () {
                //@ts-ignore
                if (pendo !== undefined && pendo.getAccountId !== undefined) {
                    console.log("Pendo > Script Loaded");
                    setisScriptLoaded(true);
                }
                else {
                    setTimeout(function () {
                        console.log("Pendo > Waiting for script to load ...");
                        waitForPendo_1();
                    }, 1000);
                }
            };
            waitForPendo_1();
        }
    }, [orchestryContext.IsPendoEnabled]);
    React.useEffect(function () {
        if (isScriptLoaded) {
            if ((userCurrentState === null || userCurrentState === void 0 ? void 0 : userCurrentState.Id) && orchestryContext.TenantGuid) {
                try {
                    //@ts-ignore
                    pendo.initialize({
                        visitor: {
                            id: userCurrentState === null || userCurrentState === void 0 ? void 0 : userCurrentState.Id,
                            orchLanguage: orchestryContext.Culture,
                            isOrchAdmin: currentUserHasRole(OrchRole.Admin),
                            isGlobalAdmin: orchestryContext.IsCurrentUserAzureGlobalAdmin,
                            currentUserRoles: orchestryContext.CurrentUserRoles.join("|")
                        },
                        account: { id: orchestryContext.TenantGuid, name: orchestryContext.TenantName }
                    });
                    //@ts-ignore
                    console.log("Pendo > Active for ".concat(pendo.getAccountId()));
                }
                catch (_a) {
                    console.log("Pendo > Error running script");
                }
            }
        }
    }, [isScriptLoaded, userCurrentState === null || userCurrentState === void 0 ? void 0 : userCurrentState.Id, orchestryContext.TenantGuid]);
};
