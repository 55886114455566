import { AppType, PermissionInfoService, PermissionType } from "app/services/permission-info";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import * as React from "react";
import { PermissionInfoAction } from "./permission-info-action.type";
export var useOrchestryPermissionInfoEditor = function () {
    var _a = React.useState([]), permissionInfoItems = _a[0], setPermissionInfoItems = _a[1];
    var _b = React.useState(false), isEditPermissionInfoModalOpen = _b[0], setIsEditPermissionInfoModalOpen = _b[1];
    var _c = React.useState({}), selectedPermissionInfoItem = _c[0], setSelectedPermissionInfoItem = _c[1];
    var _d = React.useState(PermissionInfoAction.Update), permissionInfoAction = _d[0], setPermissionInfoAction = _d[1];
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var _e = React.useState(AppType.Orchestry), selectedAppType = _e[0], setSelectedAppType = _e[1];
    React.useEffect(function () {
        PermissionInfoService.getPermissionInfoItems().then(function (items) {
            setPermissionInfoItems(items);
        });
    }, []);
    var onAddPermissionInfoClicked = React.useCallback(function () {
        setPermissionInfoAction(PermissionInfoAction.Create);
        setSelectedPermissionInfoItem({
            PermissionType: PermissionType.Application,
            AppType: selectedAppType
        });
        setIsEditPermissionInfoModalOpen(true);
    }, [selectedAppType]);
    var onTableRowClicked = function (item) {
        setPermissionInfoAction(PermissionInfoAction.Update);
        setSelectedPermissionInfoItem(item);
        setIsEditPermissionInfoModalOpen(true);
    };
    return {
        permissionInfoItems: permissionInfoItems,
        setPermissionInfoItems: setPermissionInfoItems,
        isEditPermissionInfoModalOpen: isEditPermissionInfoModalOpen,
        setIsEditPermissionInfoModalOpen: setIsEditPermissionInfoModalOpen,
        selectedPermissionInfoItem: selectedPermissionInfoItem,
        setSelectedPermissionInfoItem: setSelectedPermissionInfoItem,
        permissionInfoAction: permissionInfoAction,
        setPermissionInfoAction: setPermissionInfoAction,
        isOrchestryInternalAdmin: currentUserHasRole(OrchRole.InternalAdmin),
        onAddPermissionInfoClicked: onAddPermissionInfoClicked,
        onTableRowClicked: onTableRowClicked,
        selectedAppType: selectedAppType,
        setSelectedAppType: setSelectedAppType
    };
};
