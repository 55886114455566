import { AsyncWait } from "app/components/common/async";
import DisableArea from "app/components/common/disable-area";
import MarkdownEditor from "app/components/common/form-markdowneditor";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakeTextSmall } from "app/components/common/skeleton-loaders";
import LifecyclePolicyAssignments from "app/components/insights/workspaces/report-details/components/lifecycle-policy-assignments";
import LifecycleWorkspaceDetails from "app/components/insights/workspaces/report-details/components/lifecycle-workspace-details";
import Store from "app/store";
import { AsyncStatus } from "app/store/async";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import { LifecyclePolicyWorkspaceCurrentActionTypes } from "app/store/lifecycle-policy-workspaces/current";
import { ReportSearchSlotName } from "app/store/lifecycle-report";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import formatDataSize from "app/utils/formatDataSize";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import moment from "moment";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./archival-workflow.scss";
var requestRefreshInterval = undefined;
var ArchivalWorkflow = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var intl = useIntl();
    var dispatch = useDispatch();
    var reportItem = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.reportItem; });
    var policyWorkspace = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.item; });
    var archivalPolicy = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.archivalPolicy; });
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var history = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.history; });
    var _m = React.useState(false), loadingHistory = _m[0], setLoadingHistory = _m[1];
    var _o = React.useState(""), comment = _o[0], setComment = _o[1];
    var _p = React.useState(""), userSelection = _p[0], setUserSelection = _p[1];
    var _q = React.useState(true), approvalModalOpen = _q[0], setApprovalModalOpen = _q[1];
    var _r = React.useState(null), isWorkspaceModeApprove = _r[0], setIsWorkspaceModeApprove = _r[1];
    var _s = React.useState(false), approvalDone = _s[0], setApprovalDone = _s[1];
    var _t = React.useState(false), submitted = _t[0], setSubmitted = _t[1];
    var _u = React.useState(""), lastStatus = _u[0], setLastStatus = _u[1];
    React.useEffect(function () {
        if (props.match.params.siteId !== undefined) {
            dispatch(LifecyclePolicyWorkspaceStore.Clear());
            dispatch(LifecyclePolicyWorkspaceStore.Load(props.match.params.siteId));
            dispatch(LifecyclePolicyWorkspaceStore.LoadReportItem(props.match.params.siteId));
            dispatch(LifecyclePolicyWorkspaceStore.LoadLinks(props.match.params.siteId));
            dispatch(LifecyclePolicyWorkspaceStore.LoadNotebookAndPlanner(props.match.params.siteId));
            dispatch(LifecyclePolicyWorkspaceStore.LoadArchivalPolicy(props.match.params.siteId));
        }
        return function () {
            dispatch(LifecyclePolicyWorkspaceStore.Clear());
            //    setUpdateStatusContinously(false);
        };
    }, [props.match.params.siteId]);
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function watchForStatusUpdate() {
            var shouldRun = false;
            var state = Store.getState();
            var runAgain = function () {
                timerID = setTimeout(function () {
                    watchForStatusUpdate();
                }, 5000);
            };
            if (state.lifecyclePolicyWorkspacesCurrentState.item !== null) {
                if (state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalForced === true &&
                    (state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus === "Active" || state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus === "Extended")) {
                    shouldRun = true;
                }
                if (state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus !== "Active" &&
                    state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus !== "Extended" &&
                    state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus !== "Done" &&
                    state.lifecyclePolicyWorkspacesCurrentState.item.ArchivalStatus !== "Deleted") {
                    shouldRun = true;
                }
            }
            if (shouldRun) {
                var erroredOut_1 = true;
                dispatch(LifecyclePolicyWorkspaceStore.LoadStatus(props.match.params.siteId));
                AsyncWait(undefined, AsyncStatus.Succeeded, policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.GUID, null, LifecyclePolicyWorkspaceCurrentActionTypes.LifecyclePolicyWorkspace_Current_Load_Status).then(function () {
                    erroredOut_1 = false;
                    runAgain();
                });
                setTimeout(function () {
                    if (erroredOut_1) {
                        console.log("Status Update didn't complete... waited 30 seconds, but didn't get anything. Attempting again without reponse from last poll.");
                        // runAgain();
                    }
                }, 30000);
            }
            else {
                runAgain();
            }
        }
        watchForStatusUpdate();
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [props.match.params.siteId]);
    React.useEffect(function () {
        if (policyWorkspace !== null) {
            if (policyWorkspace.ArchivalStatus !== lastStatus) {
                //console.log("STATUS CHANGED", "FROM", lastStatus, "TO", policyWorkspace.ArchivalStatus);
                if (lastStatus !== "") {
                    dispatch(LifecyclePolicyWorkspaceStore.Load(props.match.params.siteId));
                }
                setLastStatus(policyWorkspace.ArchivalStatus);
            }
        }
    }, [policyWorkspace]);
    React.useEffect(function () {
        if (props.match.params.action !== null && props.match.params.action !== undefined) {
            if (props.match.params.action === "archive") {
                setUserSelection("archive");
            }
            if (props.match.params.action === "extend") {
                setUserSelection("extend");
            }
            if (props.match.params.action === "delete") {
                setUserSelection("delete");
            }
        }
    }, [props.match.params.action]);
    React.useEffect(function () {
        if (policyWorkspace !== null) {
            if (policyWorkspace.ArchivalStatus !== "PendingApproval") {
                setIsWorkspaceModeApprove(null);
                if (policyWorkspace.SiteId !== null && history === null) {
                    setLoadingHistory(true);
                    setTimeout(function () {
                        dispatch(LifecyclePolicyWorkspaceStore.LoadHistory(policyWorkspace.SiteId));
                        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "LifecyclePolicyWorkspace_Current_Load_History").then(function () {
                            //console.log("tenant config loaded");
                            setLoadingHistory(false);
                        });
                    }, 2000);
                }
            }
        }
    }, [policyWorkspace]);
    var SubmitWorkflow = function () {
        if (policyWorkspace !== undefined && policyWorkspace !== null) {
            dispatch(LifecyclePolicyWorkspaceStore.LoadNewStatus(true));
            dispatch(LifecyclePolicyWorkspaceStore.WorkflowActionSubmit({
                UserSelection: userSelection,
                SiteId: policyWorkspace.SiteId,
                Feedback: comment
            }));
            setApprovalDone(true);
            setApprovalModalOpen(false);
        }
    };
    React.useEffect(function () {
        if (policyWorkspace !== null && policyWorkspace !== undefined && policyWorkspace.ArchivalStatus !== "PendingApproval") {
            if (userSelection !== "") {
                setUserSelection("");
            }
            if (comment !== "") {
                setComment("");
            }
        }
    }, [policyWorkspace]);
    var getExtensionDays = function () {
        if (archivalPolicy !== null) {
            if (archivalPolicy.WorkspaceExtension_ExtensionType === "Inactivity") {
                return archivalPolicy.InactivityDaysThreshold;
            }
            else {
                return archivalPolicy.WorkspaceExtension_NumberOfDaysExtended;
            }
        }
        else {
            return "--";
        }
    };
    var RenderStatItem = function (title, icon, count, isStorage) {
        if (isStorage === void 0) { isStorage = false; }
        return reportItem === null ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement("div", { className: "stats-box" },
            React.createElement(Portlet, null,
                React.createElement("div", { className: "top-area" },
                    React.createElement("div", { className: "icon" },
                        React.createElement("i", { className: icon })),
                    React.createElement("div", { className: "right-side" },
                        React.createElement("div", { className: "count" }, isStorage ? formatDataSize(count) : count),
                        React.createElement("div", { className: "title" }, title))))));
    };
    function orderHistoryByDateDesc(a, b) {
        if (a.Date < b.Date) {
            return 1;
        }
        if (a.Date > b.Date) {
            return -1;
        }
        return 0;
    }
    var GetApprovalHistoryItem = function () {
        if (history !== null && history !== undefined && history.ArchivalHistory.length > 0) {
            var historyItem = history.ArchivalHistory.sort(orderHistoryByDateDesc).find(function (h) { return (h.Action === "ApprovedForArchive" || h.Action === "ApprovedForDelete") && h.PolicyGuid === (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalPolicyGuid); });
            if (historyItem !== null && historyItem !== undefined) {
                return historyItem;
            }
        }
        else {
            return null;
        }
    };
    var GetApprovalHistoryItemDate = function () {
        var _a;
        var date = (_a = GetApprovalHistoryItem()) === null || _a === void 0 ? void 0 : _a.Date;
        if ((date === null || date === void 0 ? void 0 : date.length) > 0) {
            var splitDate = date.split(".");
            return splitDate[0];
        }
        return "";
    };
    var GetExecutionNotificationHistoryItemDate = function () {
        var _a;
        var date = (_a = GetExecutionNotificationHistoryItem()) === null || _a === void 0 ? void 0 : _a.Date;
        if ((date === null || date === void 0 ? void 0 : date.length) > 0) {
            var splitDate = date.split(".");
            return splitDate[0];
        }
        return "";
    };
    var GetExecutionNotificationHistoryItem = function () {
        if (history !== null && history !== undefined && history.ArchivalHistory.length > 0) {
            var historyItem = history.ArchivalHistory.sort(orderHistoryByDateDesc).find(function (h) { return h.Action === "ExecutionNotificationSent" && h.PolicyGuid === (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalPolicyGuid); });
            if (historyItem !== null && historyItem !== undefined) {
                return historyItem;
            }
        }
        return null;
    };
    var GetExtendedHistoryItem = function () {
        if (history !== null && history !== undefined && history.ArchivalHistory.length > 0) {
            var historyItem = history.ArchivalHistory.sort(orderHistoryByDateDesc).find(function (h) { return h.Action === "Extended" && h.PolicyGuid === (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalPolicyGuid); });
            if (historyItem !== null && historyItem !== undefined) {
                return historyItem;
            }
        }
        return null;
    };
    var GetMarkupMessage = function () {
        return ((archivalPolicy === null || archivalPolicy === void 0 ? void 0 : archivalPolicy.Execution_Approvers_Approval_NotificationText) ||
            JSON.stringify([{ type: "text", children: [{ text: (archivalPolicy === null || archivalPolicy === void 0 ? void 0 : archivalPolicy.Execution_Approvers_Approval_NotificationText) || "" }] }]));
    };
    var GetExtendedHistoryItemDate = function () {
        var _a;
        var date = (_a = GetExtendedHistoryItem()) === null || _a === void 0 ? void 0 : _a.Date;
        if ((date === null || date === void 0 ? void 0 : date.length) > 0) {
            var splitDate = date.split(".");
            return splitDate[0];
        }
        return "";
    };
    return (React.createElement("div", { className: "archival-workflow fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: intl.formatMessage({ id: "page.archival_approval.page_title", defaultMessage: "Policy Approval" }), subTitle: intl.formatMessage({ id: "page.archival_approval.page_subtitle", defaultMessage: "Workflow" }) }),
        React.createElement(PageHeader.SetRightContent, null, currentUserHasRole(OrchRole.Admin) ? (React.createElement(React.Fragment, null,
            React.createElement(NavLink, { to: "/lifecycle/listing/" + (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteId) },
                React.createElement("button", { className: "btn btn-primary" },
                    React.createElement(FormattedMessage, { id: "page.archival_approval.view_details", defaultMessage: "View Details" }))))) : null),
        currentUserHasRole(OrchRole.Admin) ? (React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(Portlet, { className: "admin-options" },
                    React.createElement("div", { className: "wrapper" },
                        React.createElement("div", null,
                            React.createElement(FormattedMessage, { id: "page.archival_approval.admin_options", defaultMessage: "Admin Options:" }),
                            " "),
                        React.createElement(NavLink, { to: "/lifecycle/listing/" + (reportItem === null || reportItem === void 0 ? void 0 : reportItem.SiteId) },
                            React.createElement("button", { className: "btn btn-primary" },
                                React.createElement(FormattedMessage, { id: "page.archival_approval.view_details", defaultMessage: "View Details" })))))))) : null,
        loadingHistory === true ? (React.createElement("div", { style: { marginBottom: "20px" } },
            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 70 }))) : history !== null && history.ArchivalHistory.length > 0 && (GetExtendedHistoryItem() !== null || GetApprovalHistoryItem() !== null) ? (React.createElement(PortletAlert, { type: "success", icon: "alert", title: intl.formatMessage({ id: "page.archival_approval.no_action_required_title", defaultMessage: "No Action Required" }), message: policyWorkspace !== null && policyWorkspace.ArchivalStatus === "Extended" ? (React.createElement("div", { className: "no-action na-extended" },
                React.createElement("div", { className: "history-top" },
                    React.createElement(FormattedMessage, { id: "page.archival_approval.no_action_required_extended", defaultMessage: "Workspace has been extended by {username} on {date}", values: {
                            username: (_a = GetExtendedHistoryItem()) === null || _a === void 0 ? void 0 : _a.User.DisplayName,
                            date: moment
                                .utc(GetExtendedHistoryItemDate())
                                .local()
                                .format("LLL")
                        } })),
                ((_b = GetExtendedHistoryItem()) === null || _b === void 0 ? void 0 : _b.Comment) !== undefined && ((_c = GetExtendedHistoryItem()) === null || _c === void 0 ? void 0 : _c.Comment) !== null ? (React.createElement("div", { className: "comment" },
                    React.createElement("div", { className: "title" },
                        React.createElement(FormattedMessage, { id: "page.archival_approval.no_action_required_comment", defaultMessage: "Comment:" })),
                    React.createElement("div", { className: "text" }, (_d = GetExtendedHistoryItem()) === null || _d === void 0 ? void 0 : _d.Comment))) : null)) : GetApprovalHistoryItem() !== null && !(policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalSecondStageArchivedDate) && GetExecutionNotificationHistoryItem() === null ? (React.createElement("div", { className: "no-action na-approved" },
                React.createElement("div", { className: "history-top" }, archivalPolicy !== null && archivalPolicy.Option_Content_Action === "Delete" ? (React.createElement(FormattedMessage, { id: "page.archival_approval.no_action_required_approved_delete", defaultMessage: "Workspace deletion has been approved by {username} on {date}", values: {
                        username: (_e = GetApprovalHistoryItem()) === null || _e === void 0 ? void 0 : _e.User.DisplayName,
                        date: moment
                            .utc(GetApprovalHistoryItemDate())
                            .local()
                            .format("LLL")
                    } })) : (React.createElement(FormattedMessage, { id: "page.archival_approval.no_action_required_approved", defaultMessage: "Workspace archival has been approved by {username} on {date}", values: {
                        username: (_f = GetApprovalHistoryItem()) === null || _f === void 0 ? void 0 : _f.User.DisplayName,
                        date: moment
                            .utc(GetApprovalHistoryItemDate())
                            .local()
                            .format("LLL")
                    } }))),
                ((_g = GetApprovalHistoryItem()) === null || _g === void 0 ? void 0 : _g.Comment) !== undefined && ((_h = GetApprovalHistoryItem()) === null || _h === void 0 ? void 0 : _h.Comment) !== null ? (React.createElement("div", { className: "comment" },
                    React.createElement("div", { className: "title" },
                        React.createElement(FormattedMessage, { id: "page.archival_approval.no_action_required_comment", defaultMessage: "Comment:" })),
                    React.createElement("div", { className: "text" }, (_j = GetApprovalHistoryItem()) === null || _j === void 0 ? void 0 : _j.Comment))) : null)) : GetExecutionNotificationHistoryItem() !== null ? (GetExecutionNotificationHistoryItem() &&
                archivalPolicy !== null &&
                archivalPolicy !== undefined &&
                ((!((_k = GetExecutionNotificationHistoryItem()) === null || _k === void 0 ? void 0 : _k.ExtendedProperties.isSecondStage) && archivalPolicy.Execution_Type === "NotificationExecution") ||
                    (((_l = GetExecutionNotificationHistoryItem()) === null || _l === void 0 ? void 0 : _l.ExtendedProperties.isSecondStage) && archivalPolicy.SecondStage_Execution_Type === "NotificationExecution")) ? (React.createElement(FormattedMessage, { id: "page.archival_approval.no_action_required_execution_notification", defaultMessage: "Workspace was {action} automatically on on {date}", values: {
                    date: moment
                        .utc(GetExecutionNotificationHistoryItemDate())
                        .local()
                        .format("LLL"),
                    action: (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalIsDeleted) ? (React.createElement(FormattedMessage, { id: "page.archival_approval.no_action_required_execution_notification.deleted", defaultMessage: "deleted" })) : (React.createElement(FormattedMessage, { id: "page.archival_approval.no_action_required_execution_notification.archived", defaultMessage: "archived" }))
                } })) : null) : (React.createElement("span", null, "hello? anyone here?")) })) : null,
        React.createElement(Row, null,
            React.createElement(Col, { sm: 4 },
                policyWorkspace === null || (approvalDone === false && policyWorkspace.ArchivalStatus === "PendingApproval") ? (React.createElement(Portlet, { className: "approval-portlet" },
                    archivalPolicy !== null ? (React.createElement(PortletHeader, { title: archivalPolicy.Option_Content_Action === "Delete"
                            ? intl.formatMessage({ id: "page.archival_approval.actiontitle_deletion", defaultMessage: "Deletion Approval Required" })
                            : intl.formatMessage({ id: "page.archival_approval.actiontitle_archival", defaultMessage: "Archival Approval Required" }), icon: "fa fa-exclamation-triangle" })) : (React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.archival_approval.actiontitle_archival", defaultMessage: "Archival Approval Required" }), icon: "fa fa-exclamation-triangle" })),
                    React.createElement(PortletBody, null,
                        React.createElement("div", { className: "body-wrapper" }, archivalPolicy === null || policyWorkspace === null ? (React.createElement(React.Fragment, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 68 }),
                            React.createElement(SkeletonLoaderFakeTextSmall, { marginTop: 15 }),
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 57, marginTop: 5 }),
                            React.createElement(SkeletonLoaderFakeTextSmall, { marginTop: 5 }),
                            React.createElement("div", { className: "approval-buttons" },
                                React.createElement("div", { className: "approval-row" },
                                    React.createElement(SkeletonLoaderFakeBox, null),
                                    React.createElement(SkeletonLoaderFakeBox, null)),
                                React.createElement("div", { className: "approval-row" },
                                    React.createElement(SkeletonLoaderFakeBox, { marginTop: 15 }),
                                    React.createElement(SkeletonLoaderFakeBox, { marginTop: 15 }))))) : (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "approval-comment" }, archivalPolicy !== null ? React.createElement(MarkdownEditor, { style: { margin: "auto" }, readOnly: true, templateText: GetMarkupMessage(), enabledStyles: { all: true } }) : "---"),
                            React.createElement("div", { className: "comment" },
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "page.archival_approval.comments_textarea", defaultMessage: "Comments" })),
                                React.createElement("textarea", { value: comment !== null ? comment : "", className: "form-control", onChange: function (event) {
                                        setComment(event.target.value);
                                    } })),
                            React.createElement("div", { className: "approval-buttons" },
                                React.createElement("div", { className: "approval-row" },
                                    React.createElement("div", null,
                                        React.createElement("button", { type: "button", className: "btn btn-extend btn-md btn-block size-small" + (userSelection === "extend" ? " selected" : ""), onClick: function () { return setUserSelection("extend"); } },
                                            React.createElement("i", { className: "far fa-clock" }),
                                            " ",
                                            React.createElement(FormattedMessage, { id: "page.archival_approval.renew_button", defaultMessage: "Renew" }))),
                                    React.createElement("div", null,
                                        React.createElement("button", { type: "button", className: "btn btn-archive btn-md btn-block size-small" + (userSelection === "archive" ? " selected" : ""), onClick: function () { return setUserSelection("archive"); } }, archivalPolicy.Option_Content_Action === "Delete" ? (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: "fa fa-trash" }),
                                            React.createElement(FormattedMessage, { id: "page.archival_approval.archive_button_delete", defaultMessage: "Delete" }))) : (React.createElement(React.Fragment, null,
                                            React.createElement("i", { className: "fa fa-archive" }),
                                            React.createElement(FormattedMessage, { id: "page.archival_approval.archive_button_archive", defaultMessage: "Archive" }))))),
                                    archivalPolicy.Option_Content_Archival_Allow_Delete_Alternative === true ? (React.createElement("div", null,
                                        React.createElement("button", { type: "button", className: "btn btn-delete btn-md btn-block size-small" + (userSelection === "delete" ? " selected" : ""), onClick: function () { return setUserSelection("delete"); } },
                                            React.createElement("i", { className: "far fa-trash" }),
                                            " ",
                                            React.createElement(FormattedMessage, { id: "page.archival_approval.archive_button_delete_alt", defaultMessage: "Delete" })))) : null)),
                            React.createElement("div", { className: "approval-buttons" },
                                React.createElement("div", { className: "approval-row" },
                                    React.createElement("div", { className: "help-text" },
                                        React.createElement(FormattedMessage, { id: "page.archival_approval.this_will_extend", defaultMessage: "This will extend the usage of this Workspace for {days} days", values: { days: getExtensionDays() } })),
                                    archivalPolicy !== null && archivalPolicy.Option_Content_Action === "Delete" ? (React.createElement("div", { className: "help-text" },
                                        React.createElement(FormattedMessage, { id: "page.archival_approval.this_will_delete", defaultMessage: "This will approve the Workspace to be deleted" }))) : (React.createElement("div", { className: "help-text" },
                                        React.createElement(FormattedMessage, { id: "page.archival_approval.this_will_approve", defaultMessage: "This will approve the Workspace to be archived" }))),
                                    archivalPolicy.Option_Content_Archival_Allow_Delete_Alternative === true ? (React.createElement("div", { className: "help-text" },
                                        React.createElement(FormattedMessage, { id: "page.archival_approval.this_will_delete_alt", defaultMessage: "This will delete the Workspace entirely" }))) : null)),
                            React.createElement("div", { className: "approval-submit" },
                                React.createElement(DisableArea, { isEnabled: userSelection !== "", useOverlay: true },
                                    React.createElement("button", { type: "button", className: "btn btn-primary btn-md btn-block", onClick: function () { return SubmitWorkflow(); } },
                                        React.createElement(FormattedMessage, { id: "page.archival_approval.submit_button", defaultMessage: "Submit" })))))))))) : null,
                React.createElement(LifecyclePolicyAssignments, { siteId: props.match.params.siteId, minimalMode: true, Slot: ReportSearchSlotName.WorkspaceInsightsSlot })),
            React.createElement(Col, { sm: 8 },
                React.createElement(LifecycleWorkspaceDetails, { showDaysInactive: true }),
                reportItem !== null && reportItem !== undefined ? (React.createElement(React.Fragment, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { sm: 4 }, RenderStatItem(intl.formatMessage({ id: "page.archival_approval.stat_label.owners", defaultMessage: "Owners" }), "fa fa-users", reportItem.OwnersCount)),
                        React.createElement(Col, { sm: 4 }, RenderStatItem(intl.formatMessage({ id: "page.archival_approval.stat_label.members", defaultMessage: "Members" }), "fa fa-users", reportItem.MembersCount)),
                        React.createElement(Col, { sm: 4 }, RenderStatItem(intl.formatMessage({ id: "page.archival_approval.stat_label.guests", defaultMessage: "Guests" }), "fa fa-users", reportItem.GuestCount))),
                    React.createElement(Row, null,
                        React.createElement(Col, { sm: 4 }, RenderStatItem(intl.formatMessage({ id: "page.archival_approval.stat_label.documents", defaultMessage: "Documents" }), "far fa-folder-open", reportItem.SharePointTotalFileCount)),
                        React.createElement(Col, { sm: 4 }, RenderStatItem(intl.formatMessage({ id: "page.archival_approval.stat_label.storage", defaultMessage: "Storage" }), "fa fa-database", reportItem.SharePointSiteStorageUsed, true))))) : null)),
        React.createElement(Row, null,
            React.createElement(Col, { lg: 12 }))));
});
export default ArchivalWorkflow;
