import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import Directory from "app/components/directory";
import DirectoryAddExists from "app/components/directory/directory-components/directory-add-exists";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import * as React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import "./home-admin-directory.scss";
var HomeAdminDirectory = React.memo(function (props) {
    var intl = useIntl();
    var addDiv = React.useRef();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    return (React.createElement("div", { className: "home-admin-directory" },
        React.createElement(Subtitle, { title: intl.formatMessage({ id: "component.directory.home.directory", defaultMessage: "Directory" }), subtitle: intl.formatMessage({ id: "component.directory.home.dir_list", defaultMessage: "List" }) },
            currentUserHasRole(OrchRole.Admin) ? (React.createElement("div", { style: { display: "inline-block" } },
                React.createElement("a", { href: orchestryContext.TenantSharePointUrl + orchestryContext.OrchestrySiteCollectionUrl + "/Lists/WorkspaceDirectory", target: "_blank" },
                    React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "component.directory.home.view_directory_in_sp", defaultMessage: "View Directory List in SharePoint" }), faIcon: "list-alt", variant: "info" })))) : null,
            React.createElement("div", { ref: addDiv, style: { display: "inline-block" } },
                React.createElement(DirectoryAddExists, null,
                    React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "component.directory.home.add_existing_team_or_site", defaultMessage: "Add Existing Team or Site" }), faIcon: "book-open", variant: "success" })))),
        React.createElement(Directory, null)));
});
export default HomeAdminDirectory;
