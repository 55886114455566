import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import iconViva from "app/assets/img/icon-viva-engage.svg";
import AnimatedLoader from "app/components/common/animated-loader";
import FontIcon from "app/components/common/font-icon";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import * as TableList from "app/components/common/table-list";
import UsersService from "app/services/users";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import * as RequestListStore from "app/store/requests/list";
import getStringTwoLetterAcronym from "app/utils/getStringTwoLetterAcronym";
import { getRandomInt } from "app/utils/randomGen";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import RequestStatus from "../request-status";
import "./request-list.scss";
var RequestList = React.memo(function (props) {
    var intl = useIntl();
    var dispatch = useDispatch();
    var requests = useSelector(function (state) { return state.requestListState.items; });
    var totalResults = useSelector(function (state) { var _a; return (_a = state.requestListState.paging) === null || _a === void 0 ? void 0 : _a.TotalResults; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var _a = React.useState(1), currentPage = _a[0], setCurrentPage = _a[1];
    var _b = React.useState(10), currentResultsPerPage = _b[0], setCurrentResultsPerPage = _b[1];
    var _c = React.useState(false), IsUserAdmin = _c[0], setIsUserAdmin = _c[1];
    //const [isUserOnAnyWorkflowsAsApprover,setIsUserOnAnyWorkflowsAsApprover] = React.useState<undefined|boolean>();
    var _d = React.useState("all"), currentView = _d[0], setCurrentView = _d[1];
    var _e = React.useState(false), showViewSelector = _e[0], setShowViewSelector = _e[1];
    var history = useHistory();
    var _f = React.useState([
        {
            id: "0",
            title: intl.formatMessage({ id: "page.requests.filter.all_requests", defaultMessage: "All Requests" }),
            value: "all"
        },
        {
            id: "1",
            title: intl.formatMessage({ id: "page.requests.filter.my_approvals", defaultMessage: "Requests waiting for my approval" }),
            value: "my-approvals"
        },
        {
            id: "2",
            title: intl.formatMessage({ id: "page.requests.filter.my_approved_requests", defaultMessage: "Requests I have approved" }),
            value: "my-approved-requests"
        },
        {
            id: "3",
            title: intl.formatMessage({ id: "page.requests.filter.my_requests", defaultMessage: "Requests I have submitted" }),
            value: "my-requests"
        },
        {
            id: "4",
            title: intl.formatMessage({ id: "page.requests.filter.all_approvals", defaultMessage: "Requests that need approval" }),
            value: "all-approvals"
        },
        {
            id: "5",
            title: intl.formatMessage({ id: "page.requests.filter.provisioned", defaultMessage: "Provisioned Requests" }),
            value: "provisioned"
        },
        {
            id: "6",
            title: intl.formatMessage({ id: "page.requests.filter.rejected", defaultMessage: "Rejected Requests" }),
            value: "rejected"
        },
        {
            id: "7",
            title: intl.formatMessage({ id: "page.requests.filter.archived", defaultMessage: "Archived Requests" }),
            value: "archived"
        }
    ]), listViews = _f[0], setListViews = _f[1];
    var colors = ["green", "red", "orange", "blue", "purple", "yellow", "gray"];
    //console.log("Total Results", totalResults);
    React.useEffect(function () {
        if (orchestryContext.IsCurrentUserAzureGlobalAdmin || currentUserHasRole(OrchRole.Admin) || currentUserHasRole(OrchRole.InternalAdmin)) {
            setIsUserAdmin(true);
            setShowViewSelector(true);
            //setCurrentView("all");
        }
        else {
            UsersService.IsCurrentUserOnAnyWorkflowsAsApprover().then(function (isOnWorkflows) {
                console.log("IsCurrentUserOnAnyWorkflowsAsApprover", isOnWorkflows);
                if (isOnWorkflows === true) {
                    setCurrentView("my-approvals");
                }
                else {
                    setCurrentView("my-requests");
                }
                setListViews([
                    {
                        id: "0",
                        title: intl.formatMessage({ id: "page.requests.filter.my_requests", defaultMessage: "Requests I have submitted" }),
                        value: "my-requests"
                    },
                    {
                        id: "1",
                        title: intl.formatMessage({ id: "page.requests.filter.my_approvals", defaultMessage: "Requests waiting for my approval" }),
                        value: "my-approvals"
                    },
                    {
                        id: "2",
                        title: intl.formatMessage({ id: "page.requests.filter.my_approved_requests", defaultMessage: "Requests I have approved" }),
                        value: "my-approved-requests"
                    }
                ]);
                setShowViewSelector(true);
                //setIsUserOnAnyWorkflowsAsApprover(isOnWorkflows as boolean);
            });
        }
    }, [orchestryContext]);
    // React.useEffect(()=>{
    //     if(IsUserAdmin === true){
    //         setCurrentView("all");
    //     }
    // },[IsUserAdmin])
    React.useEffect(function () {
        if (showViewSelector === true) {
            dispatch(RequestListStore.Load_Page({
                items: [],
                // view: orchestryContext.IsCurrentUserAzureGlobalAdmin || OrchRole.Admin || OrchRole.InternalAdmin ? currentView : "my-approvals",
                view: currentView,
                paging: {
                    CurrentPage: currentPage,
                    ResultsPerPage: currentResultsPerPage
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, currentResultsPerPage, currentView, showViewSelector]);
    var renderType = function (request) {
        if (request.RequestType === "SharePoint") {
            return (React.createElement("div", { className: "icon-td" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconSP, alt: "SharePoint", className: "fadein-500ms" }))));
        }
        if (request.RequestType === "Team") {
            return (React.createElement("div", { className: "icon-td" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconTeams, alt: "Teams", className: "fadein-500ms" }))));
        }
        if (request.RequestType === "VivaEngageCommunity") {
            return (React.createElement("div", { className: "icon-td" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconViva, alt: "Viva Engage Community", className: "fadein-500ms" }))));
        }
        return React.createElement(React.Fragment, null);
    };
    var renderDetails = function (requestItem) {
        return (React.createElement(NavLink, { exact: true, to: "/requests/view/" + requestItem.GUID },
            React.createElement("div", { className: "view btn btn-sm btn-clean btn-icon btn-icon-sm" },
                React.createElement(FontIcon, { iconString: "eye" }))));
    };
    var tabbedNav = React.createElement(Button, null, "TEst");
    var renderStatus = function (requestItem) {
        return React.createElement(RequestStatus, { request: requestItem });
    };
    var renderLogo = function (request) {
        if (request.Image !== undefined && request.Image !== null && request.Image !== "") {
            return (React.createElement("div", { className: "request-logo with-image" },
                React.createElement("div", { className: "image-background", style: { backgroundImage: "url('" + request.Image + "')" } })));
        }
        else {
            return (React.createElement("div", { className: "request-logo" + (" color-" + colors[getRandomInt(0, 5)]), "data-pw": "request-logo" }, request.Name ? getStringTwoLetterAcronym(request.Name) : ""));
        }
    };
    return (React.createElement("div", { className: "request-list" },
        React.createElement(Subtitle, { title: intl.formatMessage({ id: "page.requests.list.title", defaultMessage: "Workspace Requests" }), subtitle: intl.formatMessage({ id: "page.requests.list.subtitle", defaultMessage: "List" }) },
            currentUserHasRole(OrchRole.Admin) && (React.createElement(NavLink, { exact: true, to: "/requests/queue" },
                React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "page.requests.request_queue", defaultMessage: "Request Queue" }), faIcon: "list-alt", variant: "success" }))),
            React.createElement(NavLink, { exact: true, to: "/requests/new" },
                React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "page.requests.new_request", defaultMessage: "New Request" }), faIcon: "plus", color: "orange" }))),
        React.createElement(Portlet, { className: "current-status", fluidHeight: true },
            React.createElement(PortletBody, null, showViewSelector ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "list-filter" },
                    React.createElement("div", { className: "filter-title" },
                        intl.formatMessage({ id: "page.requests.filter_label", defaultMessage: "Filter" }),
                        ":"),
                    React.createElement(FormDropdownSelect, { list: listViews, defaultValue: currentView, onChange: function (value) {
                            setCurrentView(value);
                            setCurrentPage(1);
                        } })),
                React.createElement(TableList.Context, { listItems: requests, asyncListLoadUID: RequestListStore.RequestListActionTypes.Request_List_Load_Page, wrappedInPortlet: false, rowClick: function (item) { return history.push("/requests/view/" + item.GUID); } },
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(TableList.TableList, null,
                                React.createElement(TableList.HeaderItem, { mobileTitle: "Logo" }),
                                React.createElement(TableList.HeaderItem, null,
                                    React.createElement(FormattedMessage, { id: "general.label.name", defaultMessage: "Name" })),
                                React.createElement(TableList.HeaderItem, { mobileTitle: "Request Date" },
                                    React.createElement(FormattedMessage, { id: "general.label.date", defaultMessage: "Date" })),
                                React.createElement(TableList.HeaderItem, { type: "centered" },
                                    React.createElement(FormattedMessage, { id: "general.label.type", defaultMessage: "Type" })),
                                React.createElement(TableList.HeaderItem, null,
                                    React.createElement(FormattedMessage, { id: "general.label.template", defaultMessage: "Template" })),
                                React.createElement(TableList.HeaderItem, null,
                                    React.createElement(FormattedMessage, { id: "general.label.requestor", defaultMessage: "Requestor" })),
                                React.createElement(TableList.HeaderItem, null,
                                    React.createElement(FormattedMessage, { id: "general.label.status", defaultMessage: "Status" })),
                                React.createElement(TableList.ContentItemCustomRender, { render: renderLogo, className: "td-logo" }),
                                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "Name", isMobileTitle: true, link: "/requests/view/{GUID}" }),
                                React.createElement(TableList.ContentItemDate, { listDataProperyName: "RequestedDate" }),
                                React.createElement(TableList.ContentItemCustomRender, { render: renderType, className: "td-type" }),
                                React.createElement(TableList.ContentItem, { listDataProperyName: "Template.Title" }),
                                React.createElement(TableList.ContentItem, { listDataProperyName: "Requestor.DisplayName" }),
                                React.createElement(TableList.ContentItemCustomRender, { render: renderStatus })),
                            React.createElement(TableList.SearchPaging, { currentPage: currentPage, currentResultsPerPage: currentResultsPerPage, setCurrentPage: setCurrentPage, setCurrentResultsPerPage: setCurrentResultsPerPage, totalResults: totalResults })))))) : (React.createElement(AnimatedLoader, { size: "med" }))))));
});
export default RequestList;
