import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as builder from "../ducks/builder";
import MenuConfigOriginal from "../layout/MenuConfig";
import { LayoutSplashScreen } from "./LayoutContext";

export class MenuConfigStatic {
   static OrgMenu = JSON.stringify(MenuConfigOriginal);
}

/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer({ children, menuConfig, layoutConfig, htmlClassService }) {
   const dispatch = useDispatch();
   const builderState = useSelector(({ builder }) => builder);
   const orchestryContext = useSelector(state => state.orchestryContextCurrentState.item);

   useEffect(() => {
      if (orchestryContext != undefined && orchestryContext.HasInstalledOnce != undefined) {
         dispatch(builder.actions.setMenuConfig(filterMenuConfig(orchestryContext, menuConfig)));
      }
   }, [dispatch, menuConfig, orchestryContext, orchestryContext.LifecycleEnabled, orchestryContext.GuestManagementEnabled]);

   useEffect(() => {
      if (layoutConfig.demo !== builderState.layoutConfig.demo) {
         dispatch(builder.actions.setLayoutConfigs(layoutConfig));
      }
   }, [dispatch, builderState, layoutConfig]);

   useEffect(() => {
      dispatch(builder.actions.setHtmlClassService(htmlClassService));
   }, [dispatch, htmlClassService]);

   return htmlClassService === builderState.htmlClassServiceObjects ? (
      // Render content when `htmlClassService` synchronized with redux store.
      <>{children}</>
   ) : (
      // Otherwise sow loading splash screen.
      <LayoutSplashScreen />
   );
}

export const filterMenuConfig = (orchestryContext, sourceMenuConfig) => {
   const currentUserRoles = orchestryContext.CurrentUserRoles;
   const isBeaconUser = currentUserRoles.includes(OrchRole.BeaconUser) || currentUserRoles.includes(OrchRole.BeaconAdmin);

   let anyShouldIncludeHeaderMenu = false;

   //Techdebt: these are the old permission styles and they should be replaced by the currentUserRoles styles
   var basePermissions = currentUserRoles;

   var newMenuConfig = { ...sourceMenuConfig };
   newMenuConfig.aside.items = newMenuConfig.aside.items.filter(item => {
      //console.log("Item", item);
      var shouldInclude = false;
      let hasChildItems = true;

      if (item.separatorIfBeacon) {
         if (isBeaconUser) {
            item.separator = true;
         }
      }

      if (item.access.some(a => basePermissions.some(p => p == a))) {
         shouldInclude = true;
         item.Displayed = true;
      } else {
         item.Displayed = false;
      }

      if (orchestryContext.LifecycleEnabled == false) {
         if (item.isLifecycle != undefined) {
            item.Displayed = false;
         }
      }
      if (orchestryContext.LifecycleEnabled == true) {
         if (item.isLifecycle != undefined) {
            item.Displayed = true;
         }
      }

      if (orchestryContext.GuestManagementEnabled == false) {
         if (item.isGuestManagement != undefined) {
            item.Displayed = false;
         }
      }
      if (orchestryContext.GuestManagementEnabled == true) {
         if (item.isGuestManagement != undefined) {
            item.Displayed = true;
         }
      }

      if (item.submenu != undefined) {
         item.submenu = item.submenu.filter(sm => {
            var shouldInclude = false;

            if (sm.access == undefined) {
               shouldInclude = true;
            } else {
               if (sm.access.some(a => basePermissions.some(p => p == a))) {
                  shouldInclude = true;
               }
            }
            return shouldInclude;
         });
      }

      if (item.hideIfNoChildren != undefined && item.hideIfNoChildren == true) {
         if (item.submenu != undefined && item.submenu.length == 0) {
            shouldInclude = false;
         }
      }

      return shouldInclude;
   });

   newMenuConfig.header.items = newMenuConfig.header.items.map(rootItem => ({
      ...rootItem,
      submenu: rootItem.submenu.filter(sm => {
         var shouldInclude = false;

         if (sm.access.some(a => basePermissions.some(p => p == a))) {
            shouldInclude = true;
            anyShouldIncludeHeaderMenu = true;
         }

         return shouldInclude;
      })
   }));

   // newMenuConfig.header.items.forEach(rootItem => {
   //    rootItem.submenu.filter((item) => {
   //       //console.log("Item", item);
   //       var shouldInclude = false;

   //       if (item.access.some(a => basePermissions.some(p => p == a))) {
   //          shouldInclude = true;
   //          item.Displayed = true;
   //       } else {
   //          item.Displayed = false;
   //       }

   //       return shouldInclude;
   //    });
   // });

   //let anyShouldIncludeHeaderMenu = false;

   newMenuConfig.header.items = newMenuConfig.header.items.map(rootItem => ({
      ...rootItem,
      submenu: rootItem.submenu.filter(sm => {
         var shouldInclude = false;

         if (sm.access.some(a => basePermissions.some(p => p == a))) {
            shouldInclude = true;
            anyShouldIncludeHeaderMenu = true;
         }

         return shouldInclude;
      })
   }));

   if (anyShouldIncludeHeaderMenu == false) {
      newMenuConfig.header.items = [];
   }

   //console.log("basePermissions", basePermissions);

   return newMenuConfig;
};
