import QuickActionsPanel from "_metronic/partials/layout/QuickActionsPanel";
import { toAbsoluteUrl } from "_metronic/utils/utils";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import RequestMy from "app/components/requests/request-my";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import * as RequestListStore from "app/store/requests/list";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./chrome-request-notifications.scss";
var ChromeRequestNotifications = React.memo(function (props) {
    var dispatch = useDispatch();
    var _a = React.useState(0), notificationCount = _a[0], setNotificationCount = _a[1];
    var requests = useSelector(function (state) { return state.requestListState.userItems; });
    var _b = useCurrentUserRoles(), currentUserHasRole = _b.currentUserHasRole, currentUserIsBeaconUser = _b.currentUserIsBeaconUser;
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    React.useEffect(function () {
        loadMyRequestData();
    }, []);
    var loadMyRequestData = function () {
        dispatch(RequestListStore.Load_User_Page({
            items: [],
            view: "my-requests",
            paging: { CurrentPage: 1, ResultsPerPage: 30 }
        }));
    };
    var toggleCustomBackground = function (turnOn) {
        var noficiationBackdrop = document.querySelector("#notification-backdrop");
        if (turnOn) {
            if (noficiationBackdrop === null) {
                var bd = document.createElement("div");
                bd.classList.add("fade");
                bd.classList.add("modal-backdrop");
                bd.classList.add("show");
                bd.id = "notification-backdrop";
                var body = document.querySelector("body");
                body === null || body === void 0 ? void 0 : body.appendChild(bd);
            }
        }
        else {
            if (noficiationBackdrop !== null) {
                var body = document.querySelector("body");
                body === null || body === void 0 ? void 0 : body.removeChild(noficiationBackdrop);
            }
        }
    };
    var removeCustomBackgroundShow = function () {
        var noficiationBackdrop = document.querySelector("#notification-backdrop");
        if (noficiationBackdrop !== null) {
            noficiationBackdrop.classList.remove("show");
        }
    };
    React.useEffect(function () {
        if (requests !== undefined && requests.length > 0) {
            var pendingRequests = requests === null || requests === void 0 ? void 0 : requests.filter(function (r) { return ["Created", "ApprovalRequired", "Approved", "ReadyToProvision", "Provisioning"].some(function (sp) { return sp === r.Status; }); });
            setNotificationCount(pendingRequests.length);
        }
    }, [requests]);
    var notifications = (React.createElement(Popover, { id: "request-notifications" },
        React.createElement(Popover.Title, null,
            React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.request_notifications.my_requests", defaultMessage: "My Requests" })),
        React.createElement(Popover.Content, null,
            React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: 400 },
                React.createElement(RequestMy, { loadInitialData: false })))));
    if (currentUserIsBeaconUser) {
        return null;
    }
    return (React.createElement("div", { className: "queues-button" + (currentUserHasRole(OrchRole.Admin) && props.isManagementOpen === true ? " open-button" : "") },
        React.createElement("div", { className: "queues-button" + (currentUserHasRole(OrchRole.Admin) && props.isManagementOpen === true ? " open-button" : "") },
            React.createElement(QuickActionsPanel, { bgImage: toAbsoluteUrl("/media/misc/bg-2.jpg"), skin: "dark", iconType: "", useSVG: "true", gridNavSkin: "light" })),
        React.createElement(OverlayTrigger, { trigger: "click", placement: "bottom-end", overlay: notifications, rootClose: true, onEnter: function () {
                loadMyRequestData();
                toggleCustomBackground(true);
            }, onExiting: function () { return removeCustomBackgroundShow(); }, onExited: function () {
                dispatch(RequestListStore.Update_IsUserLoading(true));
                toggleCustomBackground(false);
            } },
            React.createElement("div", { className: "notifications" },
                notificationCount > 0 ? React.createElement("div", { className: "notification-indicator" }) : null,
                React.createElement("i", { className: "fa fa-paper-plane" })))));
});
export default ChromeRequestNotifications;
